        /* You can add global styles to this file, and also import other style files */
        /*@import "~bootstrap/dist/css/bootstrap.css";*/
        
        body {
            background: #F2F2F2;
        }
        
        .page-principale {
            border: 1px solid #9C9E9F;
            border-radius: 5px;
            margin-left: auto;
            margin-right: auto;
            width: 1200px;
        }
        
        .navbarsh {
            background: #55555A;
            border-radius: 5px;
        }
        
        .topBottomBorder {
            margin-top: 15px;
            margin-bottom: 2px;
            margin-left: 10px;
            margin-right: 10px;
            padding-top: 5px;
        }
        
        .topBottomBorder-div {
            height: 60px;
            padding-top: 20px;
            /* padding-bottom: 10px; */
            font-family: Trebuchet MS;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: center;
            color: #000000;
            margin-left: 20px;
        }
        
        .nav-tabs .nav-item.show .nav-link,
        .nav-tabs .nav-link.active {
            background: #F2F2F2;
            border-color: #dee2e6 #dee2e6 #fff;
        }
        
        .nav-tabs .nav-link {
            width: 178px;
            background: #9C9E9F;
            margin-left: 10px;
            margin-bottom: 0px;
            border: none;
            cursor: pointer;
        }
        
        .nav-tabs {
            border-bottom: none;
        }
        
        .navText {
            color: #000000;
        }
        
        .rech {
            //light grey
            background-color: #CE93D8;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        }
        
        .container-no-margin {
            margin: 0px 0px 0px 0px;
        }
        
        .menu-topBottomBorder {
            margin-top: 5px;
            margin-bottom: 2px;
            padding-top: 5px;
        }
        
        .menu-left-background {
            height: 113px;
            background: #C4C4C4;
            border-radius: 5px;
            margin-left: 10px;
            margin-right: 10px;
        }
        
        .menu-right-background {
            height: 113px;
            border: 1px solid #9C9E9F;
            box-sizing: border-box;
            border-radius: 5px;
            margin-right: 10px;
        }
        
        .menu-subtitle-principal-font {
            font-family: Trebuchet MS;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            /* identical to box height */
            display: flex;
            align-items: center;
            color: #000000;
        }
        
        .menu-subtitle-font {
            font-family: Trebuchet MS;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #000000;
        }
        
        .menu-subtitle-margin {
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
        
        .col-12p {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            -ms-flex: 0 0 12%;
            flex: 0 0 12%;
            max-width: 12%;
        }
        
        .col-15p {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            -ms-flex: 0 0 15%;
            flex: 0 0 15%;
            max-width: 15%;
        }
        
        .col-18p {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            -ms-flex: 0 0 18%;
            flex: 0 0 18%;
            max-width: 18%;
        }
        
        .col-20p {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;
        }
        
        .col-40p {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            -ms-flex: 0 0 68%;
            flex: 0 0 40%;
            max-width: 40%;
        }
        
        .col-68p {
            position: relative;
            width: 100%;
            padding-right: 15px;
            padding-left: 15px;
            -ms-flex: 0 0 68%;
            flex: 0 0 68%;
            max-width: 68%;
        }
        
        .btn {
            font-family: Trebuchet MS;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            align-items: center;
            text-align: center;
            height: 26px;
        }
        
        .btn-custom-inactive {
            background: #F2F2F2;
            border: 1px solid #9C9E9F;
            box-sizing: border-box;
            border-radius: 2px;
            color: #9C9E9F;
            pointer-events: none;
        }
        
        .btn-custom-footer {
            background: #9C9E9F;
            border: 1px solid #9C9E9F;
            box-sizing: border-box;
            border-radius: 2px;
            color: #121213;
            margin-top: 15px;
            margin-bottom: 15px;
            height: 30px;
        }
        
        .btn-custom-danger {
            background: #FFFFFF;
            border: 1px solid #E95F47;
            box-sizing: border-box;
            border-radius: 2px;
            color: #E95F47;
        }
        
        .btn-custom-active {
            background: #FFFFFF;
            border: 1px solid #00AEC7;
            box-sizing: border-box;
            border-radius: 2px;
            color: #00AEC7;
        }
        
        .btn-custom-active:hover {
            background: #00AEC7;
            color: #FFFFFF;
        }
        
        .btn-custom-danger:hover {
            background: #E95F47;
            color: #FFFFFF;
        }
        
        .btn-custom-active:hover {
            background: #00AEC7;
            color: #FFFFFF;
        }
        
        .form-control-custom {
            font-family: Trebuchet MS;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            align-items: center;
            color: #55555A;
            background: #FFFFFF;
            border-radius: 2px;
            height: 26px;
            padding-top: 1px;
            padding-bottom: 1px;
        }
        
        table {
            background: #FFFFFF;
            box-sizing: border-box;
            border-radius: 5px;
        }
        
        .table th {
            font-family: Trebuchet MS;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            align-items: center;
            color: #000000;
            border-top: none;
        }
        
        .table td {
            font-family: Trebuchet MS;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            /* identical to box height */
            align-items: center;
            color: #000000;
            vertical-align: middle;
            border-top: none;
        }
        
        .table-active {
            background: #DCEEEF;
        }
        
        .table-container {
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 2px;
            margin-bottom: 10px;
            background: #FFFFFF;
            border: 1px solid #9C9E9F;
            box-sizing: border-box;
            border-radius: 5px;
            text-align: center;
        }
        
        .table thead th {
            vertical-align: middle;
            border-bottom: 1px solid #9C9E9F;
        }
        
        .table tbody td {
            vertical-align: middle;
            border-bottom: 1px solid #9C9E9F;
        }
        /* custom checkbox */
        
        input[type=checkbox] {
            display: none;
        }
        
        input[type=checkbox]+label {
            position: relative;
            height: 24px;
            width: 24px;
            display: block;
            border: 1px solid #00AEC7;
            box-sizing: border-box;
            border-radius: 2px;
            cursor: pointer;
        }
        /* Provide a border when hovered and when the checkbox before it is checked */
        
        input[type=checkbox]+label:hover,
        input[type=checkbox]:checked+label {
            /* border: solid 5px #F00; */
            box-shadow: 0 0 1px #F00;
            /* Soften the jagged edge */
        }
        /* 
- Create a pseudo element :after when checked and provide a tick
- Center the content
*/
        
        input[type=checkbox]:checked+label:after {
            content: '\2713';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            color: #FFFFFF;
            line-height: 1;
            font-size: 19px;
            text-align: center;
            /* test */
            background: #00AEC7;
            border: 1px solid #00AEC7;
            box-sizing: border-box;
            border-radius: 2px;
        }
        
        input[type=checkbox][name=allCheck]:checked+label:after {
            content: "\2713";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            color: #FFFFFF;
            line-height: 1;
            font-size: 18px;
            text-align: center;
            background: #00AEC7;
            border: 1px solid #00AEC7;
            box-sizing: border-box;
            border-radius: 2px;
        }
        
        input[type=checkbox][name=partialCheck]:checked+label:after {
            content: "\2014";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            color: #FFFFFF;
            line-height: 1;
            font-size: 18px;
            text-align: center;
            background: #00AEC7;
            border: 1px solid #00AEC7;
            box-sizing: border-box;
            border-radius: 2px;
        }
        
        .logo {
            margin-left: 5%;
            margin-bottom: 10px;
        }
        
        .loader {
            border: 10px solid #f3f3f3;
            border-top: 10px solid #000000;
            border-radius: 50%;
            width: 100px;
            height: 100px;
            animation: spin 3s linear infinite;
        }
        
        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
        
        .pagination {
            width: 148px;
            height: 25px;
            font-family: Trebuchet MS;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            display: flex;
            align-items: center;
            text-align: right;
            float: right;
        }
        
        .pagination-spin {
            width: 20px;
            height: 20px;
            border: 1px solid #9C9E9F;
            box-sizing: border-box;
            border-radius: 50%;
            color: #9C9E9F;
            margin: 0px 5px 0px 5px;
            font-size: 15px;
            text-align: center;
            // padding: 0px 2px 0px 2px;
        }
        
        #previousButton,
        #nextButton {
            cursor: pointer;
        }
        
        #previousButton.inactive,
        #nextButton.inactive {
            cursor: default;
            opacity: 50%;
            pointer-events: none;
        }
        
        #previousButton:hover,
        #nextButton:hover {
            text-decoration: none;
        }
        
        .pointer {
            cursor: pointer;
        }
        
        .alert-custom {
            width: 100%;
            margin: 5px 10px;
            text-align: center;
        }
        
        .width-100 {
            width: 100%;
        }
        
        .nav-tabs .nav-item .nav-link {
            margin-bottom: 0;
            border: none;
            border-color: none;
            cursor: pointer;
        }
        
        .page-item {
            color: #9C9E9F;
            vertical-align: middle;
        }